import { userService } from "./user.service";
import { store } from "../_helpers";
import { appActions, userActions, scheduleActions } from "../_actions";

export const confService = {
  confInfo,
  confList,
  confListStudents,
  ticketsInfo,
  validateEmail,
  validatePassword,
  validateUrl,
  validateLogin,
  confListParticipants,
  getConfDateTimeStr,
  siteInfo,
  siteList,
  siteListRent,
};

function validateEmail(email) {
  // var re = /\S+@\S+\.\S+/;
  // ^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$
  let re =
    /^[-a-z0-9!#$%&'*+/=?^_`{|}~]+(?:\.[-a-z0-9!#$%&'*+/=?^_`{|}~]+)*@(?:[a-z0-9]([-a-z0-9]{0,61}[a-z0-9])?\.)*/;
  return re.test(email.toLowerCase());
}

function validatePhone(val) {
  let re = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/;
  return re.test(val);
}

function validateLogin(pwd) {
  let re = /^[a-zA-Z0-9-_]+$/;
  return re.test(pwd);
}

function validatePassword(pwd) {
  let re = /^[a-zA-Z0-9-_!@#$%^&*()+|`"№;%:?*()\\\/.,'{}<>=-]+$/;

  return re.test(pwd);
}

function validateUrl(url) {
  let re =
    /https?\:\/\/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;

  return re.test(url);
}

function getConfDateTimeStr(start_src, end_src) {
  if (!start_src) return "";

  if (!end_src) return "";

  let start_date = start_src.substr(0, 10).split("-").reverse().join(".");
  let end_date = end_src.substr(0, 10).split("-").reverse().join(".");
  let start_time = start_src.substr(11, 5);
  let end_time = end_src.substr(11, 5);

  if (start_date != end_date) {
    return start_date + " " + start_time + " - " + end_date + " " + end_time;
  }

  return start_date + " " + start_time + "-" + end_time;
}

function confInfo(id, cbD, cbE) {
  userService.getWrapper(
    "api/conference/" + id,
    (data) => {
      cbD(data);
    },
    (error) => {
      if (error != "") {
        cbE(error);
      }
    }
  );
}

function siteInfo(id, cbD, cbE) {
  userService.getWrapper(
    `api/building/${id}`,
    (data) => {
      cbD(data);
    },
    (error) => {
      if (error != "") {
        cbE(error);
      }
    }
  );
}

function siteList(cbD, cbE) {
  let url = "api/building";

  userService.getWrapper(
    url,
    (data) => {
      cbD(data);
    },
    (error) => {
      if (error != "") {
        cbE(error);
      }
    }
  );
}

function siteListRent(cbD, cbE, start_date, end_date) {
  let url = "api/building-all";

  if (start_date) {
    url = url + "?start_time=" + start_date;
    if (end_date) url = url + "&end_time=" + end_date;
  } else if (end_date) {
    url = url + "?end_time=" + end_date;
  }

  userService.getWrapper(
    url,
    (data) => {
      cbD(data);
    },
    (error) => {
      if (error != "") {
        cbE(error);
      }
    }
  );
}

function confList(for_student, cbD, cbE) {
  let url = "api/conference";
  if (for_student) url += "/all";

  userService.getWrapper(
    url,
    (data) => {
      cbD(data);
    },
    (error) => {
      if (error != "") {
        cbE(error);
      }
    }
  );
}

function confListStudents(cbD, studentConference = 0) {
  userService.getWrapper(
    "api/conference/" + studentConference + "/students" + (studentConference ? "?forStudent=1" : ""),
    (data) => {
      let partip_data = [];
      for (let i = 0; i < data.data.length; i++) {
        let anketa = data.data[i].user.anketa;
        let tmpObj = {
          conf_id: data.data[i].conference.id,
          conf_name: data.data[i].conference.name,
          user_id: data.data[i].user.id,
          user_name: data.data[i].user.name ?? "",
          user_email: data.data[i].user.email ?? "",
          avatar: data.data[i].user.avatar ?? "",
          anketa: anketa ?? {},
          ticket_id: 0,
          ticket_name: "",
          updated_at: "",
          count: 1,
        };
        if (!studentConference) {
          tmpObj.ticket_id = data.data[i].ticket_type.id;
          tmpObj.ticket_name = data.data[i].ticket_type.name;
          tmpObj.updated_at = data.data[i].updated_at;
        }

        if (anketa) {
          let tmp_str =
            (anketa.lastname ?? "") + " " +
            (anketa.firstname ?? "") + " " +
            (anketa.patronymic ?? "");
          if (tmp_str.length > 2) {
            tmpObj.user_name = tmp_str;
          }
        }

        if (!tmpObj.user_name && tmpObj.user_email) {
          tmpObj.user_name = tmpObj.user_email;
        }
        partip_data.push(tmpObj);
      }
      cbD(partip_data);
    },
    (error) => {
      store.dispatch(userActions.set_error(error));
    }
  );
}

function confListParticipants(forStudent = false) {
  const confs_data = [];

  confList(
    forStudent,
    function (data) {
      let urls = [];
      let confs_data = [];
      let partip_data = [];

      if (data.data.length > 0) {
        confs_data = [...data.data];
        store.dispatch(userActions.set_conf_list(confs_data));
        let tmp_partips = [];

        for (let i = 0; i < data.data.length; i++)
          urls.push(`api/conference/${data.data[i].id}`);

        userService.getAsync(
          urls,
          function (data) {
            for (let i = 0; i < data.length; i++) {
              confs_data[i].participants_count =
                data[i].data.participants_count;
              confs_data[i].sponsors_count = data[i].data.sponsors_count;
            }

            const state = store.getState();

            store.dispatch(userActions.set_conf_list(confs_data));
          },
          function (err) {
            store.dispatch(userActions.set_error(err));
          }
        );
      } else {
        store.dispatch(userActions.set_conf_list([]));
      }
    },
    function (err) {
      store.dispatch(userActions.set_error(err));
    }
  );
}

function ticketsInfo(id, cbD, cbE) {
  userService.getWrapper(
    `api/ticket-type?conference_id=${id}`,
    (data) => {
      cbD(data);
    },
    (error) => {
      if (error != "") {
        cbE(error);
      }
    }
  );
}
