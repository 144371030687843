import * as React from "react";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import SpeakerInfo from "./SpeakerInfo";
import i18next from "i18next";
import "../css/style.css";
import offlineLogo from "../img/offline.png";
import onlineLogo from "../img/online.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 600,
  maxWidth: 1200,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 10,
  p: 4,
  fontSize: 16,
};

const headerWidth = 140;

function replaceName(name) {
  if (!name) return name;

  return name;
}

function getUrls(s0) {
  console.log(s0);
  if (!s0) return;
  let s = s0;

  /*
  var re =
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;

  let array = [...s.matchAll(re)];

  for (let i = 0; i < array.length; i++) {
    let url_tmp = array[i][0] + "";
    let url_correct = url_tmp;

    if (!url_tmp.includes("https://") && !url_tmp.includes("http://")) {
      url_correct = "https://" + url_tmp;
    }

    s0 = s0.replace(
      url_tmp,
      '<span style="color: blue" ><a href="' +
        url_correct +
        '" target="_blank">' +
        url_tmp +
        "</a></span>"
    );
  }
  */

  s0 = "<div>" + s0 + "</div>";
  console.log(s0);
  return s0;
}

export default function SlotInfo(props) {
  const [count, setCount] = React.useState(0);
  const [openQ, setQ] = React.useState(false);
  const [qtext, setQtext] = React.useState("");

  const {
    handleClose,
    sendQ,
    data,
    open,
    hasTicket,
    isPublic,
    redirectToConf,
    openSpeaker,
    isHybrid,
    questionable,
    isOrg,
    createTranslation,
    delTranslation,
    stopTranslation,
  } = props;

  console.log(data);

  const no_text = props.texts.to_be_specified;
  const no_url_text = props.texts.viewtrans;

  React.useEffect(() => {});

  function openUrl() {
    if (data.url_online) {
      if (
        data.url_online.indexOf("https:") != 0 &&
        data.url_online.indexOf("http:") != 0
      )
        window.open("https://" + data.url_online);
      else window.open(data.url_online);
    }
  }

  function onSendQ() {
    if (qtext != "") {
      sendQ(qtext + " (" + i18next.t("quest_for") + " " + data.sp_str + ")");
      setQ(false);
      setQtext("");
    }
  }

  console.log(data);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            ...style,
            minWidth: props.isMobile ? window.screen.width - 10 : 600,
            maxWidth: props.isMobile ? window.screen.width - 10 : 1200,
          }}
        >
          {!props.isMobile && (
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              style={{ display: "flex", flexDirection: "row" }}
            >
              <div style={{ fontWeight: "700", width: headerWidth }}>
                {props.texts.theme}:
              </div>{" "}
              {data.name ? replaceName(data.name) : no_text}
            </Typography>
          )}
          {props.isMobile && (
            <>
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <div style={{ fontWeight: "700", width: headerWidth }}>
                  {props.texts.theme}:
                </div>
              </Typography>
              <div> {data.name ? replaceName(data.name) : no_text}</div>
            </>
          )}

          {data.description && data.description != "_service" && (
            <Typography
              id="transition-modal-description"
              sx={{ mt: 2 }}
              style={{
                display: "flex",
                flexDirection: "row",
                whiteSpace: "pre-wrap",
              }}
            >
              <div style={{ fontWeight: "700", minWidth: headerWidth }}>
                {props.texts.desc}:
              </div>
              <div
                dangerouslySetInnerHTML={{ __html: getUrls(data.description) }}
              ></div>
            </Typography>
          )}

          {((data.description && data.description != "_service") ||
            !data.description) &&
            data.slot_type != "service_lesson" &&
            !props.isMobile && (
              <Typography
                id="transition-modal-description"
                sx={{ mt: 2 }}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  whiteSpace: "pre-wrap",
                }}
              >
                <div style={{ fontWeight: "700", width: headerWidth }}>
                  {props.texts.speakers}:
                </div>{" "}
                <div>
                  {data.speakers &&
                    data.speakers.map((item, index) => (
                      <span>
                        {index > 0 ? ", " : ""}
                        <span
                          style={{
                            color: "blue",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={() => openSpeaker(item)}
                        >
                          {item.name}
                        </span>
                      </span>
                    ))}
                </div>
              </Typography>
            )}
          {((data.description && data.description != "_service") ||
            !data.description) &&
            data.slot_type != "service_lesson" &&
            props.isMobile && (
              <>
                <Typography
                  id="transition-modal-description"
                  sx={{ mt: 2 }}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  <div style={{ fontWeight: "700", width: headerWidth }}>
                    {props.texts.speakers}:
                  </div>
                </Typography>
                <div>
                  {data.speakers &&
                    data.speakers.map((item, index) => (
                      <span>
                        {index > 0 ? ", " : ""}
                        <span
                          style={{
                            color: "blue",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={() => openSpeaker(item)}
                        >
                          {item.name}
                        </span>
                      </span>
                    ))}
                </div>
              </>
            )}
          {data.type != "online" && !props.isMobile && (
            <Typography
              id="transition-modal-description"
              sx={{ mt: 2 }}
              style={{
                display: "flex",
                flexDirection: "row",
                whiteSpace: "pre-wrap",
              }}
            >
              <div style={{ fontWeight: "700", width: headerWidth }}>
                {props.texts.room}:
              </div>{" "}
              {data.room_number ? data.room_number : no_text}
            </Typography>
          )}
          {data.type != "online" && props.isMobile && (
            <>
              <Typography
                id="transition-modal-description"
                sx={{ mt: 2 }}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  whiteSpace: "pre-wrap",
                }}
              >
                <div style={{ fontWeight: "700", width: headerWidth }}>
                  {props.texts.room}:
                </div>
              </Typography>
              <div>{data.room_number ? data.room_number : no_text}</div>
            </>
          )}
          {!props.isMobile && (
            <Typography
              id="transition-modal-description"
              sx={{ mt: 2 }}
              style={{
                display: "flex",
                flexDirection: "row",
                whiteSpace: "pre-wrap",
              }}
            >
              <div style={{ fontWeight: "700", width: headerWidth }}>
                {props.texts.time}:
              </div>{" "}
              <div>
                {data.time ? data.time : no_text} - {data.endtime ?? ""}
              </div>
            </Typography>
          )}
          {props.isMobile && (
            <>
              <Typography
                id="transition-modal-description"
                sx={{ mt: 2 }}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  whiteSpace: "pre-wrap",
                }}
              >
                <div style={{ fontWeight: "700", width: headerWidth }}>
                  {props.texts.time}:
                </div>
              </Typography>
              <div>
                {" "}
                {data.time ? data.time : no_text} - {data.endtime ?? ""}
              </div>
            </>
          )}
          {!props.isMobile &&
            data.slot_type != "service_lesson" &&
            isHybrid && (
              <Typography
                id="transition-modal-description"
                sx={{ mt: 2 }}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  whiteSpace: "pre-wrap",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                  }}
                >
                  <div style={{ fontWeight: "700", width: headerWidth + 20 }}>
                    {props.texts.format}:
                  </div>
                  <div style={{ minWidth: 160 }}>
                    {data.type == "online" && i18next.t("online_text")}
                    {data.type == "offline" && i18next.t("offline_text")}
                    {data.type == "hybrid" && i18next.t("hybrid_text")}
                  </div>
                  {(data.type == "online" || data.type == "hybrid") && (
                    <img style={{ marginLeft: 10 }} src={onlineLogo} />
                  )}
                  {(data.type == "offline" || data.type == "hybrid") && (
                    <img style={{ marginLeft: 10 }} src={offlineLogo} />
                  )}
                </div>
              </Typography>
            )}

          {props.isMobile && data.slot_type != "service_lesson" && isHybrid && (
            <>
              <Typography
                id="transition-modal-description"
                sx={{ mt: 2 }}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  whiteSpace: "pre-wrap",
                }}
              >
                <div style={{ fontWeight: "700", width: headerWidth + 20 }}>
                  {props.texts.format}:
                </div>
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                }}
              >
                <div>
                  {data.type == "online" && i18next.t("online_text")}
                  {data.type == "offline" && i18next.t("offline_text")}
                  {data.type == "hybrid" && i18next.t("hybrid_text")}
                </div>
                {(data.type == "online" || data.type == "hybrid") && (
                  <img style={{ marginLeft: 10 }} src={onlineLogo} />
                )}
                {(data.type == "offline" || data.type == "hybrid") && (
                  <img style={{ marginLeft: 10 }} src={offlineLogo} />
                )}
              </div>
            </>
          )}

          {data.url_online && !isPublic && (
            <Typography
              id="transition-modal-description"
              sx={{ mt: 2 }}
              style={{
                color: "blue",
                textDecoration: "underline",
                cursor: "pointer",
                width: 250,
              }}
            >
              <span onClick={openUrl}>{props.texts.urltrans}</span>
            </Typography>
          )}

          {data.url_online && isPublic && (
            <Typography
              id="transition-modal-description"
              sx={{ mt: 2 }}
              style={{
                color: "blue",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              <span onClick={redirectToConf}>{no_url_text}</span>
            </Typography>
          )}
          {hasTicket && (
            <div style={{ marginTop: 10 }}>
              {!openQ &&
                data.slot_type != "service_lesson" &&
                questionable == 1 && (
                  <Button onClick={() => setQ(true)}>
                    {props.texts.ask_q}
                  </Button>
                )}

              {openQ && (
                <div style={{ marginTop: 10, fontSize: 16 }}>
                  <span>{props.texts.qtext}</span>
                  <textarea
                    id="area1"
                    className="form__input"
                    placeholder={props.texts.text}
                    name="q"
                    multiline
                    onChange={(e) => setQtext(e.target.value)}
                    value={qtext}
                    style={{ marginTop: 5 }}
                  />
                  <Button onClick={onSendQ}>{props.texts.send}</Button>
                  <Button
                    onClick={() => {
                      setQ(false);
                      setQtext("");
                    }}
                  >
                    {props.texts.cancel}
                  </Button>
                </div>
              )}
            </div>
          )}

          <div style={{ marginTop: 10, fontSize: 16 }}>
            <div style={{ marginBottom: 10, fontSize: 18, fontWeight: 700 }}>
              Трансляция
            </div>
            {data.translation && data.translation.iframe_live && (
              <div>
                <div stle={{ marginTop: 5, marginBottom: 10 }}>
                  Live Трансляция
                </div>
                <div
                  style={{ border: "1px solid black" }}
                  dangerouslySetInnerHTML={{
                    __html: getUrls(data.translation.iframe_live),
                  }}
                ></div>
              </div>
            )}
            {data.translation && data.translation.iframe_chat && (
              <div>
                <div stle={{ marginTop: 10, marginBottom: 10 }}>
                  Чат трансляции
                </div>
                <div
                  style={{ border: "1px solid black" }}
                  dangerouslySetInnerHTML={{
                    __html: getUrls(data.translation.iframe_chat),
                  }}
                ></div>
              </div>
            )}
            {isOrg && data.translation && (
              <div style={{ marginTop: 10 }}>{data.translation.service_id}</div>
            )}
            {isOrg && data.translation && (
              <>
              <div style={{ marginTop: 10 }}>Ссылка на live трансляцию:</div>
              <div style={{ marginTop: 5, color:'blue' }}>
              <a href={data.translation.live_url}>
                {data.translation.live_url}
              </a>
              </div>
              </>
            )}

            {isOrg && !(data.translation && data.translation.service_id) && (
              <Button onClick={createTranslation}>Создать</Button>
            )}
            {isOrg && data.translation && data.translation.service_id && (
              <Button onClick={delTranslation}>Удалить</Button>
            )}
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
