import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import TextFieldI18N from "./TextFieldI18N";
import TextFieldHint from "./TextFieldHint";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const LANG_LIST = ["ru", "en", "cn"];

export default function EditDialog(props) {
  const {
    handleClose,
    label,
    nameLabel,
    title,
    oldVal,
    dialogOpen,
    dialogType,
    fileStatus,
    onFileUpload,
    isSlotService,
    createTranslation,
    delTranslation,
    isMultilang,
    langList,
  } = props;

  const { t } = useTranslation();

  const [count, setCount] = React.useState(0);
  const [value, setValue] = React.useState(oldVal ? oldVal.name : "");
  const [editNameDisabled, setEditNameDisabled] = React.useState(false);

  const [slotType, setSlotType] = React.useState("");
  const [description, setDesc] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [city, setCity] = React.useState("");
  const [comm, setComm] = React.useState("");
  const [seats, setSeats] = React.useState("");
  const [name_i18n, setNameI18N] = React.useState({});
  const [description_i18n, setDescI18N] = React.useState({});
  const [city_i18n, setCityI18N] = React.useState({});
  const [comment_i18n, setCommI18N] = React.useState({});

  const [kTrans, setKTrans] = React.useState(null);

  const [isServiceSlot, setServiceSlot] = React.useState(false);

  const hintEnabled = true;

  React.useEffect(() => {
    setValue(oldVal ? oldVal.name : "");
    setNameI18N(oldVal && oldVal.name_i18n ? oldVal.name_i18n : {});

    if (dialogType == "speaker") {
      console.log(oldVal);
      setDesc(oldVal.description ? oldVal.description : "");
      setDescI18N(oldVal.entity ? oldVal.entity.description_i18n : {});
      setCity(oldVal.city ? oldVal.city : "");
      setPhone(oldVal.phone ? oldVal.phone : "");
      setComm(oldVal.comment ? oldVal.comment : "");
      setEmail(oldVal.email ? oldVal.email : "");
      if (oldVal.description_i18n) setDescI18N(oldVal.description_i18n);

      if (oldVal.city_i18n) setCityI18N(oldVal.city_i18n);

      if (oldVal.comment_i18n) setCommI18N(oldVal.comment_i18n);
    } else if (dialogType == "theme") {
      if (oldVal) {
        if (oldVal.slot_type == "lesson") {
          setDesc(oldVal.entity ? oldVal.entity.description : "");
          setValue(oldVal.entity ? oldVal.entity.name : "");
          setNameI18N(oldVal.entity ? oldVal.entity.name_i18n : {});
          setDescI18N(oldVal.entity ? oldVal.entity.description_i18n : {});
          setKTrans(oldVal.entity.translation ?? null);
          setSlotType(oldVal.entity.type);
        } else setDesc(oldVal.description ? oldVal.description : "");

        if (oldVal.slot_type == "service_lesson") {
          setValue(oldVal.entity ? oldVal.entity.name : "");
          setEditNameDisabled(true);
        }

        setServiceSlot(oldVal.isSlotService ? true : false);
      }
    } else if (dialogType == "room") {
      setDesc(oldVal.description ? oldVal.description : "");
      setDescI18N(oldVal.description_i18n ? oldVal.description_i18n : {});
      setSeats(oldVal.seats);
    }
  }, [oldVal, dialogType]);

  function editVal(e) {
    setValue(e.target.value);
    setCount(count + 1);
  }

  function saveTheme() {
    if (value != "") {
      const tmpObj = { name: value, name_i18n: name_i18n };

      if (dialogType == "speaker") {
        tmpObj.description = description;
        tmpObj.description_i18n = description_i18n;
        tmpObj.phone = phone;
        tmpObj.city = city;
        tmpObj.comment = comm;
        tmpObj.email = email;
        tmpObj.name_i18n = name_i18n;
        tmpObj.city_i18n = city_i18n;
        tmpObj.comment_i18n = comment_i18n;
      } else if (dialogType == "theme" && !isSlotService) {
        tmpObj.description = description;
        tmpObj.description_i18n = description_i18n;
      } else if (dialogType == "room") {
        tmpObj.seats = seats;
        tmpObj.description = description;
        tmpObj.description_i18n = description_i18n;
      }

      handleClose(tmpObj);
      setCount(0);
      setValue("");
      setValue("");
      setPhone("");
      setDesc("");
      setEmail("");
      setComm("");
      setCity("");
      setNameI18N({});
      setDescI18N({});
      setCityI18N({});
      setCommI18N({});
      setSeats("");
      setKTrans(null);
      setSlotType(null);
      setEditNameDisabled(false);
    }
  }

  function cancelTheme() {
    handleClose(null);
    setCount(0);
    setValue("");
    setValue("");
    setPhone("");
    setDesc("");
    setEmail("");
    setComm("");
    setCity("");
    setNameI18N({});
    setDescI18N({});
    setCityI18N({});
    setCommI18N({});
    setKTrans(null);
    setSlotType(null);
    setEditNameDisabled(false);
  }

  function onDrop(acceptedFiles, rejectedFiles) {
    if (acceptedFiles.length > 0) {
      onFileUpload(acceptedFiles[0]);
    }
  }

  return (
    <Dialog open={dialogOpen}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextFieldI18N
          autoFocus={true}
          margin="dense"
          id="name"
          label={nameLabel}
          hint={hintEnabled ? i18next.t("hint_dialog_name") : ""}
          fullWidth
          variant="standard"
          disabled={editNameDisabled}
          value={isMultilang ? name_i18n ?? {} : value}
          onChange={(e, tabName) => {
            let tmpI18N = name_i18n;
            tmpI18N[tabName] = e.target.value;

            if (isMultilang) {
              setNameI18N(tmpI18N);
              setValue(tmpI18N["ru"]);
            } else {
              setValue(e.target.value);
            }
            setCount(count + 1);
          }}
          langList={langList}
          isMultilang={isMultilang}
        />
        {(dialogType == "speaker" ||
          dialogType == "room" ||
          (dialogType == "theme" && !isSlotService)) && (
          <TextFieldI18N
            margin="dense"
            id="desc"
            label={t("dialog_desc")}
            hint={hintEnabled ? i18next.t("hint_dialog_desc") : ""}
            fullWidth
            variant="standard"
            multiline
            value={isMultilang ? description_i18n ?? {} : description}
            onChange={(e, tabName) => {
              let tmpI18N = description_i18n;
              if (!tmpI18N) tmpI18N = { ru: "", en: "", cn: "" };

              tmpI18N[tabName] = e.target.value;

              if (isMultilang) {
                setDescI18N(tmpI18N);
                setDesc(tmpI18N["ru"]);
              } else {
                setDesc(e.target.value);
              }
              setCount(count + 1);
            }}
            langList={langList}
            isMultilang={isMultilang}
          />
        )}
        {dialogType == "speaker" && (
          <TextFieldHint
            margin="dense"
            id="email"
            label={t("dialog_email")}
            hint={hintEnabled ? i18next.t("hint_dialog_email") : ""}
            fullWidth
            variant="standard"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setCount(count + 1);
            }}
          />
        )}
        {dialogType == "speaker" && (
          <TextFieldHint
            margin="dense"
            id="phone"
            label={t("dialog_phone")}
            hint={hintEnabled ? i18next.t("hint_dialog_phone") : ""}
            fullWidth
            variant="standard"
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
              setCount(count + 1);
            }}
          />
        )}
        {dialogType == "speaker" && (
          <TextFieldI18N
            margin="dense"
            id="phone"
            label={t("dialog_city")}
            hint={hintEnabled ? i18next.t("hint_dialog_city") : ""}
            fullWidth
            variant="standard"
            value={city_i18n}
            onChange={(e, tabName) => {
              let tmpI18N = city_i18n;
              tmpI18N[tabName] = e.target.value;

              setCityI18N(tmpI18N);
              setCity(tmpI18N["ru"]);
              setCount(count + 1);
            }}
            langList={langList}
            isMultilang={isMultilang}
          />
        )}
        {dialogType == "speaker" && (
          <TextFieldI18N
            margin="dense"
            id="comm"
            label={t("dialog_comment")}
            hint={hintEnabled ? i18next.t("hint_dialog_comment") : ""}
            fullWidth
            variant="standard"
            multiline
            value={isMultilang ? comment_i18n : comm}
            onChange={(e, tabName) => {
              let tmpI18N = comment_i18n;
              tmpI18N[tabName] = e.target.value;

              if (isMultilang) {
                setCommI18N(tmpI18N);
                setComm(tmpI18N["ru"]);
              } else {
                setComm(e.target.value);
              }
              setCount(count + 1);
            }}
            langList={langList}
            isMultilang={isMultilang}
          />
        )}
        {dialogType == "room" && (
          <TextFieldHint
            margin="dense"
            id="room"
            label={t("dialog_seats")}
            hint={hintEnabled ? i18next.t("hint_dialog_seats") : ""}
            fullWidth
            variant="standard"
            value={seats}
            onChange={(e) => {
              setSeats(e.target.value);
              setCount(count + 1);
            }}
          />
        )}

        {dialogType == "theme" &&
          oldVal.slot_type !== "service_lesson" &&
          !isSlotService && (
            <div style={{ marginTop: 10 }}>
              <div style={{ paddingBottom: 5 }}>{t("presentation_file")}</div>
              <Dropzone onDrop={onDrop} class="visually--hidden">
                {({ getRootProps, getInputProps, isDragActive }) => (
                  <div
                    style={{
                      textAlign: "center",
                      verticalAlign: "center",
                      width: 400,
                      height: 50,
                      background: "#36B2F1",
                      borderRadius: 5,
                    }}
                    {...getRootProps()}
                    className={classNames("dropzone", {
                      "dropzone--isActive": isDragActive,
                    })}
                  >
                    <input {...getInputProps()} />

                    {fileStatus == 0 &&
                      (isDragActive ? (
                        <p style={{ fontSize: 12 }}>
                          {t("dropzone_file_upload")}
                        </p>
                      ) : (
                        <p
                          style={{ fontSize: 12, padding: 10, borderRadius: 5 }}
                        >
                          {t("dropzone_title_hint")}
                        </p>
                      ))}
                    {fileStatus == 1 && (
                      <p style={{ fontSize: 12, padding: 10 }}>
                        {t("dropzone_file_uploaded")}
                      </p>
                    )}
                  </div>
                )}
              </Dropzone>
            </div>
          )}

        {dialogType == "theme" &&
          oldVal.slot_type !== "service_lesson" &&
          slotType &&
          slotType != "offline" &&
          !isSlotService && (
            <div style={{ marginTop: 10, fontSize: 16 }}>
              <div>Трансляция "Кинескоп"</div>

              {kTrans && (
                <div>
                  <div style={{ marginTop: 10 }}>{kTrans.service_id}</div>
                  <div style={{ marginTop: 10 }}>
                    Ссылка на live трансляцию:
                  </div>
                  <div
                    style={{
                      marginTop: 5,
                      color: "blue",
                      textDecoration: "underline",
                    }}
                  >
                    <a href={kTrans.live_url}>{kTrans.live_url}</a>
                  </div>
                </div>
              )}

              {!kTrans && (
                <Button onClick={createTranslation}>
                  {i18next.t("create")}
                </Button>
              )}
              {kTrans && kTrans.service_id && (
                <Button onClick={delTranslation}>
                  {i18next.t("btn_delete")}
                </Button>
              )}
            </div>
          )}
      </DialogContent>
      <DialogActions>
        <Button onClick={saveTheme}>{t("btn_save")}</Button>
        <Button onClick={cancelTheme}>{t("btn_cancel")}</Button>
      </DialogActions>
    </Dialog>
  );
}
