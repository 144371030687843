import logo from "./logo.svg";
import "./App.css";
import React from "react";
import { Router, Route } from "react-router-dom";
import { PrivateRoute } from "./_components";
import { history } from "./_helpers";
import { MainPage } from "./MainPage";
import { LoginPage, RegistrPage, VKPage, LeaderPage } from "./LoginPage";
import AgrPage from "./LoginPage/AgrPage";
import VerifyPage from "./LoginPage/VerifyPage";
import { ClientConfPage, ClientListPage } from "./ClientPages";
import PasswordResetPage from "./LoginPage/PasswordResetPage";
import PasswordForgotPage from "./LoginPage/PasswordForgotPage";
import ModerationPage from "./LoginPage/ModerationPage";
import { VotingPage } from "./ClientPages/VotingPage";
import { VoteResPage } from "./ClientPages/VoteResPage";

const routes = [
  "/conf_param",
  "/conf_prog",
  "/team_org",
  "/role_org",
  "/new_conf",
  "/blds",
  "/speakers",
  "/sponsors",
  "/nav",
  "/client_view",
  "/partips",
  "/conf_view",
  "/tickets",
  "/helpinfo",
  "/settings",
  "/support",
  "/tasks",
  "/anketa-fields",
  "/ticket-moderation",
  "/votelist",
  "/newvote",
  "/userlist",
  "/new_site",
  "/booking",
  "/sites",
];

const routes_id = [
  "/conf_param/:id",
  "/conf_prog/:id",
  "/nav/:id",
  "/client_view/:id",
  "/partips/:id",
  "/conf_view/:id",
  "/editvote/:id",
  "/site_param/:id",
  "/site_view/:id",
];

export class App extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document.title = "Naviconf";
  }

  render() {
    return (
      <Router history={history}>
        <Route path="/login" component={LoginPage} />
        <Route path="/registration" component={RegistrPage} />
        <Route path="/agreement" component={AgrPage} />
        <Route path="/email-verified" component={VerifyPage} />
        <Route path="/password-reset" component={PasswordResetPage} />
        <Route path="/password-forgot" component={PasswordForgotPage} />
        <Route path="/vkontakte-callback" component={VKPage} />
        <Route path="/leaderid-callback" component={LeaderPage} />
        <Route path="/event/:id" component={ClientConfPage} />
        <Route path="/events" component={ClientListPage} />
        <Route path="/votes/:id" component={VotingPage} />
        <Route path="/voteres/:id" component={VoteResPage} />
        <PrivateRoute exact path="/" component={MainPage} />
        <PrivateRoute path="/purchased-:pid" component={MainPage} />
        <Route path="/ticket-moderation" component={ModerationPage} />
        {routes.map((item, index) => (
          <PrivateRoute exact path={item} component={MainPage} key={index} />
        ))}
        {routes_id.map((item, index) => (
          <PrivateRoute path={item} component={MainPage} key={1000 + index} />
        ))}
      </Router>
    );
  }
}

export default App;
