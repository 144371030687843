import React from "react";
import { connect } from "react-redux";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Badge,
} from "@material-ui/core";
import { createMuiTheme, withStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import i18next from "i18next";
import { appActions, userActions } from "../_actions";
import "../css/style.css";
import "../css/fonts.css";
import circleLogo from "../img/circle.png";
import allconfLogo from "../img/allconf2.png";
import roleLogo from "../img/role.png";
import timetableLogo from "../img/timetable.png";
import scheduleLogo from "../img/schedule.png";
import clientLogo from "../img/client.png";
import navLogo from "../img/nav.png";
import speakerLogo from "../img/speakers.png";
import sponsLogo from "../img/spons.png";
import teamLogo from "../img/team.png";
import partipsLogo from "../img/partips.png";
import ncLogo from "../img/logo.png";
import baseLogo from "../img/bases.png";
import placeLogo from "../img/place.png";
import suppLogo from "../img/supp.png";
import settLogo from "../img/sett.png";
import sconfLogo from "../img/settings_w.png";
import plusLogo from "../img/plus.png";

import { serverUrl, agrUrl, helpUrl } from "../_constants";

const ACTIVE_COLOR = "#36B2F1";

const menu_superuser_data = [
  {
    page: "userlist",
    title: "Список пользователей",
    img: partipsLogo,
    child: [],
  },
];
const menu_org_data = [
  {
    page: "confs_title",
    title: "Мероприятия",
    isHidden: true,
    img: "",
    child: [
      { page: "confs", title: "Все мероприятия", img: allconfLogo },
      { page: "new_conf", title: "Новое мероприятие", img: plusLogo },
      //{ page: "sites", title: "sites" },
    ],
  },
  {
    page: "conf_param_main",
    title: "Редактор мероприятия",
    logoHidden: true,
    img: "",
    child: [
      { page: "conf_param", title: "Параметры мероприятия", img: sconfLogo },
      { page: "conf_prog", title: "Конструктор", img: timetableLogo },
      { page: "nav", title: "Навигация", img: navLogo },
      { page: "client_view", title: "Вид для клиента", img: clientLogo },
    ],
  },
  { page: "tasks", img: scheduleLogo, child: [] },
  {
    page: "bases",
    title: "Базы",
    img: baseLogo,
    child: [
      { page: "speakers", title: "Спикеры", img: speakerLogo },
      { page: "sponsors", title: "Спонсоры", img: sponsLogo },
      { page: "blds", title: "Площадки", img: placeLogo },
    ],
  },
  {
    page: "votes",
    title: "Опросы",
    img: baseLogo,
    child: [
      { page: "votelist", title: "votelist" },
      { page: "newvote", title: "add vote" },
    ],
  },
  {
    page: "partips",
    title: "Участники мероприятия",
    img: partipsLogo,
    child: [],
  },
  { page: "anketa-fields", img: scheduleLogo, child: [] },
  {
    page: "team_role",
    title: "Команда и роли",
    img: "",
    isHidden: true,
    child: [
      { page: "team_org", title: "Команда", img: teamLogo },
      { page: "role_org", title: "Роли", img: roleLogo },
    ],
  },
  { page: "settings", title: "Настройки", img: settLogo, child: [] },
  {
    page: "helpmenu",
    title: "Помощь",
    img: suppLogo,
    child: [
      { page: "helpinfo", title: "Руководство", img: "" },
      { page: "support", title: "Поддержка", img: "" },
      { page: "politics", title: "Политика конфиденциальности", img: "" },
    ],
  },
];

const menu_data_hide = [
  { page: "confs", title: "Все мероприятия", img: allconfLogo },
  { page: "new_conf", title: "Новое мероприятие", img: plusLogo },
  { page: "conf_param", title: "Параметры мероприятия", img: sconfLogo },
  { page: "conf_prog", title: "Конструктор", img: timetableLogo },
  { page: "nav", title: "Навигация", img: navLogo },
  { page: "client_view", title: "Вид для клиента", img: clientLogo },
  { page: "tasks", img: scheduleLogo, child: [] },
  { page: "anketa-fields", img: scheduleLogo, child: [] },
  { page: "speakers", title: "Спикеры", img: speakerLogo },
  { page: "sponsors", title: "Спонсоры", img: sponsLogo },
  { page: "blds", title: "Площадки", img: placeLogo },
  { page: "partips", title: "Участники мероприятия", img: partipsLogo },
  { page: "team_org", title: "Команда", img: teamLogo },
  { page: "role_org", title: "Роли", img: roleLogo },
  { page: "settings", title: "Настройки", img: settLogo },
  {
    page: "helpmenu",
    title: "Помощь",
    img: suppLogo,
    child: [],
  },
];

const menu_student_data = [
  {
    page: "confs_title",
    title: "Мероприятия",
    img: allconfLogo,
    child: [],
  },
  {
    page: "tickets",
    title: "Билеты",
    img: scheduleLogo,
    child: [],
  },
];

const menu_bld_data = [
  {
    page: "confs_title",
    title: "Площадки",
    isHidden: true,
    img: "",
    child: [
      { page: "confs", title: "Все площадки", img: allconfLogo },
      { page: "new_site", title: "Новая площадка", img: plusLogo },
      { page: "booking", title: "Бронирования", img: scheduleLogo },
    ],
  },
  { page: "settings", title: "Настройки", img: settLogo, child: [] },
];

class AppMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { page: this.props.app.page, menu_data: [], userType: "" };
  }

  goTo = (path) => {
    this.props.dispatch(appActions.moveTo(path));
  };

  getActive = (text) => {
    const { menu_data } = this.state;

    for (let i = 0; i < menu_data.length; i++) {
      if (menu_data[i].page == text) {
        return text;
      }

      if (menu_data[i].child.length > 0) {
        for (let j = 0; j < menu_data[i].child.length; j++) {
          if (menu_data[i].child[j].page == text) {
            return menu_data[i].page;
          }
        }
      }
    }

    return "other";
  };

  setPage = (page) => {
    let id = 0;
    if (this.props.conf && this.props.conf.id && this.props.conf.id > 0)
      id = this.props.conf.id;

    const { classes, dispatch } = this.props;

    if (page == "politics") {
      window.open(agrUrl);
    } else if (page == "helpinfo") {
      window.open(helpUrl);
    } else if (page == "support") {
      //const emailTo = 'support@naviconf.com';
      //window.open(`mailto:${emailTo}`);
      dispatch(appActions.setpage("support"));
    } else if (page == "helpmenu") {
      dispatch(appActions.setpage("helpinfo"));
    } else if (page == "team_role") {
      dispatch(appActions.setpage("team_org"));
    } else if (page == "confs_title") {
      dispatch(appActions.setpage("confs"));
    } else if (page == "bases") {
      dispatch(appActions.setpage("speakers"));
    } else if (page == "new_conf") {
      dispatch(userActions.set_conf(null));
      dispatch(appActions.setpage(page));
    } else if (page == "conf_param_main") {
      //dispatch(userActions.set_conf_selected());
      //dispatch(appActions.setpage(page, id));
    } else if (
      id > 0 &&
      (page == "conf_prog" || page == "nav" || page == "client_view")
    )
      dispatch(appActions.setpage(page, id));
    else dispatch(appActions.setpage(page));
  };

  showChild = (data) => {
    const self = this;
    const { classes, dispatch } = this.props;
    const { page, lang } = this.props.app;

    return (
      <ul className="header__sublist">
        {data.length > 0 &&
          data.map((item, index) => {
            let bgColor = "";

            if (page == item.page) bgColor = ACTIVE_COLOR;

            return (
              <li
                className={item.img ? "header__iconitem" : "header__subitem"}
                onClick={() => self.setPage(item.page)}
                key={index}
                style={{ backgroundColor: bgColor, borderRadius: 10 }}
              >
                {item.img && (
                  <a className="link-icon" style={{ paddingBottom: 5 }}>
                    <img src={item.img} style={{ marginRight: 10 }} />
                    {i18next.t(item.page)}
                  </a>
                )}
                {!item.img && (
                  <a className="header__sublink">{i18next.t(item.page)}</a>
                )}
              </li>
            );
          })}
      </ul>
    );
  };

  static getDerivedStateFromProps(props, current_state) {
    if (props.userType != "" && current_state.userType != props.userType) {
      if (props.userType == "superuser")
        return { menu_data: [...menu_superuser_data], userType: "superuser" };
      if (props.userType == "org")
        return { menu_data: [...menu_org_data], userType: "org" };
      if (props.userType == "team_member")
        return { menu_data: [...menu_org_data], userType: "team_member" };
      if (props.userType == "student")
        return { menu_data: [...menu_student_data], userType: "student" };
      if (props.userType == "building")
        return { menu_data: [...menu_bld_data], userType: "building" };

      return null;
    }
    return null;
  }

  render() {
    const { page, lang } = this.props.app;

    const self = this;

    const { menu_data } = this.state;

    // <div class="header__nav" >
    // <div class="header__nav-row">
    //
    // <div class="sidebar" >
    //  <div class="sidebar-top">

    const { classes, dispatch, open, onOpen } = this.props;

    return (
      <div>
        <aside
          className="sidebar"
          style={{ width: open ? 300 : 60, zIndex: 8999 }}
        >
          <div
            className="sidebar-top"
            style={{ justifyContent: open ? "start" : "center" }}
          >
            <div className="toggle" onClick={onOpen}>
              <span />
              <span />
              <span />
            </div>
            {open && <img src={ncLogo} />}
          </div>

          {!self.props.open && (
            <div>
              <ul className="nav">
                {this.props.userType != "student" &&
                  menu_data_hide.map((item, index) => (
                    <li
                      key={index}
                      title={i18next.t(item.page)}
                      onClick={() => self.setPage(item.page)}
                      style={{ marginLeft: -10 }}
                    >
                      <img src={item.img} style={{ marginTop: 25 }} />
                    </li>
                  ))}

                {this.props.userType == "student" &&
                  menu_data.map((item, index) => (
                    <li
                      key={1000 + index}
                      title={i18next.t(item.page)}
                      onClick={() => self.setPage(item.page)}
                      style={{ marginLeft: -10 }}
                    >
                      <img src={item.img} style={{ marginTop: 25 }} />
                    </li>
                  ))}
              </ul>
            </div>
          )}

          <nav
            id="sidebar-nav"
            data-simplebar="init"
            style={{ overflow: "auto"  }}
          >
            <div
              className="simplebar-content-wrapper"
              tabIndex="0"
              role="region"
              aria-label="scrollable content"
              style={{ height: "100%" }}
            >
              <div className="simplebar-content" style={{ padding: "0px" }}>
                <ul className="nav">
                  {self.props.open &&
                    menu_data &&
                    menu_data.length > 0 &&
                    menu_data.map((item, index) => {
                      let bgColor = "";
                      if (page == item.page) bgColor = ACTIVE_COLOR;

                      if (item.child.length > 0) {
                        return (
                          <li className="list-submenu" key={index}>
                            {!item.isHidden && (
                              <a
                                className="link-icon link-toggle-submenu open"
                                style={{ paddingBottom: 5 }}
                                onClick={() => self.setPage(item.page)}
                              >
                                <img
                                  src={item.img}
                                  style={{ marginRight: 10 }}
                                />
                                <span>{i18next.t(item.page)}</span>
                              </a>
                            )}
                            {self.showChild(item.child)}
                          </li>
                        );
                      }
                      return (
                        <li
                          style={{
                            backgroundColor: bgColor,
                            borderRadius: 10,
                          }}
                        >
                          <a
                            className="link-icon"
                            onClick={() => self.setPage(item.page)}
                          >
                            <img src={item.img} style={{ marginRight: 10 }} />
                            {i18next.t(item.page)}
                          </a>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </nav>
        </aside>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { app, users, authentication } = state;
  const { user } = authentication;
  const { unresolvedAppealCount } = app;
  return {
    user,
    users,
    app,
  };
}

const connectedAppMenu = connect(mapStateToProps)(AppMenu);
export { connectedAppMenu as AppMenu };
