import React from "react";
import { history, store } from "../_helpers";
import { userService, confService } from "../_services";

import "../css/style.css";
import { connect } from "react-redux";
import { userActions, appActions } from "../_actions";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import i18next from "i18next";
import confLogo from "../img/conf_logo.png";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";

import { ConferenceNotSelected } from "../_components/ConferenceNotSelected";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

import CopyContentIcon from "@material-ui/icons/FileCopy";

import RoomItem from "./RoomItem";

import { serverUrl } from "../_constants";

const TEXT_NAME_NOT_EMPTY = "Имя файла не должно быть пустым";
const TEXT_LOAD_FILE = "Загрузите файл";
const TITLE_LOAD_FILE = "Загрузить файл";
const TEXT_DROP_FOR_LOAD =
  "Перетащите файл сюда или нажмите для загрузки файла";
const TEXT_FILE_LOADED = "Файл загружен";
const TEXT_SELECT_DIR = "Выберите каталог!";
const TEXT_FOR_BUY =
  "Для включения возможности продажи билетов свяжитесь с sales@naviconf.com";

const err_text = "Не удалось выполнить запрос";
const start_end_date_text = "Дата окончания не должна быть раньше даты начала";
const start_date_in_past_text = "Нельзя создать площадку на прошедшие даты!";
const SAVE_CONF_TEXT = "Сохранить площадку";

class CreateSitePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: "one",
      isOpen: false,
      loadingTickets: false,
      streamUrl: "",
      sponsors: [],
      sp_list: [],
      tickets: [],
      tickets_tmp: [],
      name: "",
      id: 0,
      theme: "ИТ, Технологии, экономика",
      date: "3",
      adress: "4",
      part_count: "0",
      room_count: "0",
      start_date: null,
      end_date: null,
      blds: [],
      selectedBldId: 0,
      bldAddr: "",
      bldDialog: false,
      bldId: 0,
      spId: 0,
      spDialog: false,
      dName: "",
      dAddress: "",
      dEmail: "",
      format: "hybrid",
      contacts: "",
      wifi: "",
      faq: "",
      comment: "",
      urlt: "",
      url_old: "",
      url_id: "",
      is_published: 0,
      brief: "",
      brief_id: 0,
      city: "",
      street: "",
      bld: "",
      korp: "",
      map_link: "",
      map_site: "",
      rooms_data: [],
      tmpitem: null,
      roomOpenState: {},
      feedback: [],
    };
    // store.subscribe(this.storeChange)
  }

  storeChange = (e) => {
    const { classes, dispatch } = this.props;

    if (store.getState().user.isLoading == false) {
      if (store.getState().user.error == "") {
        dispatch(appActions.setpage("conf_list"));
      }
    }
  };

  componentDidMount() {
    const { selectedSite } = this.props.user;

    console.log(selectedSite);

    if (!(selectedSite && selectedSite.id)) {
      this.props.dispatch(appActions.setpage("new_site"));
    }

    if (selectedSite && selectedSite.id) {
      let img_url = "";

      if (selectedSite.documents) {
        let img_data =
          selectedSite.documents[selectedSite.documents.length - 1];
        if (img_data) img_url = img_data.path;
      }
      this.setState({ img_url });

      this.getSiteInfo(selectedSite.id);
      this.getRoomsList(selectedSite.id);
      this.getReviews();
    } else {
    }
  }

  setSiteToState(confData) {
    this.setState({
      id: confData.id,
      name: confData.name,
      comment: confData.description,
      address: confData.address,
      tags: confData.event_types,
      map_link: confData.map_link,
    });
  }

  getReviews = () => {
    const { selectedSite, info } = this.props.user;
    let self = this;

    userService.getWrapper(
      `api/feedback?entity_type=building&entity_id=${selectedSite.id}`,
      (data) => {
        if (data.data) {
          self.setState({ feedback: [...data.data] });
        }
      },
      (error) => {
        if (error != "") {
          console.log(error);
        }
      }
    );
  };

  addTmpRoom = () => {
    let tmpitem = {
      number: "",
      description: "",
      equipment: "",
      schedule: {
        base: {
          hours: { from: "", to: "" },
          cost: "",
          "daycost-for-hourrooms": "",
        },
      },
    };
    this.setState({ tmpitem });
  };

  saveRoom = (index) => {
    let self = this;

    const { tmpitem, rooms_data, id } = this.state;

    let params = "api/room";

    if (index < 0) {
      const { number, description, equipment, schedule, seats } = tmpitem;

      if (!number) {
        alert("Заполните название");
        return;
      }

      params =
        "api/room?number=" +
        number +
        "&building_id=" +
        id +
        "&description=" +
        description +
        "&equipment=" +
        equipment +
        "&seats=" +
        seats;

      userService.postWrapper(
        params,
        { schedule: schedule },
        (data) => {
          console.log(data);
          self.setState({ tmpitem: null });
          self.getRoomsList(this.state.id);
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      const { number, description, equipment, schedule, seats } =
        rooms_data[index];

      if (!number) {
        alert("Заполните название");
        return;
      }

      params =
        "api/room/" +
        rooms_data[index].id +
        "?number=" +
        number +
        "&building_id=" +
        id +
        "&description=" +
        description +
        "&equipment=" +
        equipment +
        "&seats=" +
        seats;

      userService.patchWrapperBody(
        params,
        { schedule: schedule },
        (data) => {
          console.log(data);
        },
        (err) => {
          console.log(err);
        }
      );
    }
  };

  delRoom = (index) => {
    let self = this;
    const res_dialog = window.confirm("Удалить помещение?");
    if (res_dialog == true) {
      if (index < 0) this.setState({ tmpitem: null });
      else {
        userService.delWrapper(
          "api/room/" + this.state.rooms_data[index].id,
          (data) => {
            self.getRoomsList(self.state.id);
          },
          (err) => {
            console.log(err);
          }
        );
      }
    }
  };

  clickExpand = (index) => {
    let { roomOpenState } = this.state;

    if (!roomOpenState[index + ""]) roomOpenState[index + ""] = false;

    roomOpenState[index + ""] = !roomOpenState[index + ""];

    console.log(roomOpenState);

    this.setState({ roomOpenState });
  };

  roomInputChange = (index, name, val) => {
    const { rooms_data, tmpitem } = this.state;

    let item = null;

    if (index < 0) {
      if (name == "time_from") {
        tmpitem.schedule.base.hours.from = val;
      } else if (name == "time_to") {
        tmpitem.schedule.base.hours.to = val;
      } else if (name == "price_hour") {
        tmpitem.schedule.base.cost = val;
      } else if (name == "price_day") {
        tmpitem.schedule.base["daycost-for-hourrooms"] = val;
      } else {
        tmpitem[name] = val;
      }
      this.setState({ tmpitem });

      return;
    }

    item = rooms_data[index];

    if (!rooms_data[index].schedule)
      rooms_data[index].schedule = {
        base: {
          hours: {
            from: "",
            to: "",
          },
          cost: "",
          "daycost-for-hourrooms": "",
        },
      };

    if (name == "time_from") {
      rooms_data[index].schedule.base.hours.from = val;
    } else if (name == "time_to") {
      rooms_data[index].schedule.base.hours.to = val;
    } else if (name == "price_hour") {
      rooms_data[index].schedule.base.cost = val;
    } else if (name == "price_day") {
      rooms_data[index].schedule.base["daycost-for-hourrooms"] = val;
    } else {
      rooms_data[index][name] = val;
    }
    this.setState({ rooms_data });
  };

  getRoomsList = (id) => {
    const { classes, dispatch } = this.props;
    let self = this;

    if (!id) return;

    userService.getWrapper(
      `api/room-all?building_id=${id}`,
      (data) => {
        if (data.data) {
          let tmp_data = data.data.map((item) => item.id);
          self.getRoomsData(tmp_data);
        }
      },
      (error) => {
        if (error != "") {
          dispatch(userActions.set_error(error));
        }
      }
    );
  };

  getRoomsData = (ids) => {
    let self = this;
    let urls = [];
    for (let i = 0; i < ids.length; i++) urls.push(`api/room/${ids[i]}`);

    userService.getAsync(
      urls,
      function (data) {
        let tmp_data = data.map((item) => item.data);
        console.log(tmp_data);
        self.setState({ rooms_data: tmp_data });
      },
      function (err) {
        console.log(err);
      }
    );
  };

  getBrief = () => {};

  saveBrief = (conf_id = undefined) => {
    const { classes, dispatch } = this.props;

    let { id, brief, brief_id } = this.state;

    if (conf_id) id = +conf_id;

    if (id >= 0 && brief) {
      let post_data = {
        documentable_id: id,
        documentable_type: "conference",
        type: "presentation_template",
        description: brief,
        access: "team",
        link: "https://naviconf.com/",
      };

      if (brief_id > 0) {
        post_data.id = brief_id;
      }

      userService.postWrapper(
        "api/document",
        post_data,
        (data) => {},
        (error) => {
          if (error != "") {
            dispatch(userActions.set_error(err_text));
          }
        }
      );
    }

    if (id >= 0 && brief == "" && brief_id) {
      userService.delWrapper(
        "api/document/" + brief_id,
        (data) => {
          console.log(data);
        },
        (error) => {
          if (error != "") {
            dispatch(userActions.set_error(err_text));
          }
        }
      );
    }
  };

  getSiteInfo = (id, is_save = false) => {
    const self = this;
    const { classes, dispatch } = this.props;

    confService.siteInfo(
      id,
      (data) => {
        if (data.status == 200) {
          console.log(data.data);

          self.setSiteToState(data.data);

          //if (is_save) dispatch(userActions.set_site({ ...data.data }));
        }
      },
      (error) => {
        dispatch(userActions.set_error(err_text));
      }
    );
  };

  editSponsors = () => {
    const { id } = this.state;

    this.setState({ spId: id, spDialog: true });
  };

  resetConf = () => {
    this.setState({
      name: "",
      start_time: null,
      end_time: null,
      selectedBldId: null,
      wifi: "",
      comment: "",
      faq: "",
      contacts: "",
    });
  };

  showTextForBuy = () => {
    const { classes, dispatch } = this.props;

    dispatch(userActions.set_error(TEXT_FOR_BUY, "info"));
  };

  onDrop = (acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length > 0) {
      // this.setState({ selectedFile: acceptedFiles[0],loadedProgress: 0, uploadState:0});

      this.uploadFile(acceptedFiles[0]);
    }
  };

  uploadFile(selectedFile) {
    const self = this;
    const { classes, dispatch } = this.props;
    const data_upload = new FormData();
    data_upload.append("path", selectedFile);
    data_upload.append("documentable_type", "building");
    data_upload.append("type", "gallery");
    data_upload.append("documentable_id", this.state.id);

    /*
    userService.uploadFile(
      `api/conference/${this.state.id}/upload`,
      data_upload,
      (data) => {
        if (data && data.data && data.data.image)
          self.setState({ image: data.data.image });
        else dispatch(userActions.set_error(err_text));
      },
      (error) => {
        dispatch(userActions.set_error(err_text));
      }
    );
    */

    userService.uploadFile(
      "api/document",
      data_upload,
      function (data) {
        self.getDocuments();
      },
      function (error) {
        store.dispatch(userActions.set_error(error));
      }
    );
  }

  getDocuments = () => {};

  saveTicket = (index) => {
    const { id, tickets_tmp } = this.state;
    const { classes, dispatch } = this.props;

    const tmp_t = { ...tickets_tmp[index] };

    if (tmp_t.name != "") {
      const ticketId = tmp_t.id;

      // let post_data = {conference_id: id,...tmp_t};
      const post_data = { conference_id: id, name: tmp_t.name };

      if (tmp_t.cost > 0) post_data.cost = tmp_t.cost;
      else post_data.cost = null;

      let self = this;
      this.setState({ loadingTickets: true });

      if (ticketId > 0) {
        userService.patchWrapperBody(
          `api/ticket-type/${ticketId}`,
          post_data,
          (data) => {
            self.getTickets(self.state.id);
          },
          (error) => {
            if (error != "") {
              self.setState({ error, loadingTickets: false });
              dispatch(userActions.set_error(err_text));
            }
          }
        );
      } else {
        userService.postWrapper(
          "api/ticket-type",
          post_data,
          (data) => {
            self.getTickets(self.state.id);
          },
          (error) => {
            if (error != "") {
              dispatch(userActions.set_error(err_text));
            }
          }
        );
      }
    }
  };

  delTicket = (index) => {
    const { id, tickets_tmp } = this.state;
    const { classes, dispatch } = this.props;
    const tmp_t = { ...tickets_tmp[index] };
    const ticketId = tmp_t.id;
    let self = this;

    if (ticketId > 0) {
      this.setState({ loadingTickets: true });

      userService.delWrapper(
        `api/ticket-type/${ticketId}`,
        (data) => {
          self.setState({ loadingTickets: false });
          self.getTickets(self.state.id);
        },
        (error) => {
          if (error != "") {
            self.setState({ loadingTickets: false });
            dispatch(userActions.set_error(error));
          }
        }
      );
    } else {
      tickets_tmp.splice(index, 1);
      this.setState({ tickets_tmp: [...tickets_tmp] });
    }
  };

  editTicket = (indexT, val, type) => {
    if (val != null) {
      const { tickets_tmp } = this.state;

      if (indexT >= 0) {
        const tmp_obj = { ...tickets_tmp[indexT] };
        if (type == "name") tmp_obj.name = val;
        if (type == "cost") tmp_obj.cost = val;

        tickets_tmp[indexT] = { ...tmp_obj };
        this.setState({ tickets_tmp });
      }
    }
  };

  addTicket = () => {
    const { tickets_tmp } = this.state;

    tickets_tmp.push({ id: 0, name: "", cost: "" });
    this.setState({ tickets_tmp });
  };

  getTickets = (id) => {
    let self = this;
    const { classes, dispatch } = this.props;

    if (id > 0) {
      this.setState({ loadingTickets: true });

      userService.getWrapper(
        `api/ticket-type?conference_id=${id}`,
        (data) => {
          if (data.data) {
            let tmp_data = [...data.data];
            tmp_data = tmp_data.map((item, index) => ({
              ...item,
              cost: item.cost ? item.cost : 0,
            }));
            self.setState({ tickets_tmp: tmp_data, loadingTickets: false });
          } else self.setState({ tickets_tmp: [], loadingTickets: false });
        },
        (error) => {
          if (error != "") {
            dispatch(userActions.set_error(error));
          }
        }
      );
    }
  };

  getSponsors = (id) => {
    let self = this;
    const { classes, dispatch } = this.props;

    let getUrl = "api/sponsor";
    if (id > 0) getUrl += `?conference_id=${id}`;

    userService.getWrapper(
      getUrl,
      (data) => {
        if (id > 0) {
          if (data.data)
            self.setState({
              sponsors: [...data.data],
              room_count: `${data.data.length}`,
            });
          else self.setState({ sponsors: [] });
        } else if (data.data) self.setState({ sp_list: [...data.data] });
        else self.setState({ sp_list: [] });
      },
      (error) => {
        if (error != "") {
          self.setState({ error });
          dispatch(userActions.set_error(error, "error"));
        }
      }
    );
  };

  setBld = (id) => {
    let addr = "";
    const { blds } = this.state;

    for (let i = 0; i < blds.length; i++)
      if (blds[i].id == id) addr = blds[i].address;

    this.setState({ selectedBldId: id, bldAddr: addr });
  };

  getBuildings = (id = 0) => {
    let self = this;

    userService.getWrapper(
      "api/building",
      (data) => {
        if (data.data) {
          if (id > 0) {
            let addr = "";
            for (let i = 0; i < data.data.length; i++) {
              if (data.data[i].id == id) {
                addr = data.data[i].address;
                break;
              }
            }
            self.setState({
              blds: [...data.data],
              bldAddr: addr,
              selectedBldId: id,
            });
          } else self.setState({ blds: [...data.data] });
        }
      },
      (error) => {
        if (error != "") {
          self.setState({ error, isLoading: false });
        }
      }
    );
  };

  setSiteData = () => {
    const { classes, dispatch } = this.props;

    const {
      name,
      comment,

      address,
      map_link,
      map_site,
      tags,
      id,
    } = this.state;

    const self = this;

    if (!name) {
      let tmp_str = "Заполните поля - название";

      dispatch(userActions.set_error(tmp_str, "warning"));

      return;
    }

    const post_obj = {
      name: name,
      description: comment,
      //contacts: contacts,
      //population: part_count,
      //type: format,
      address: address,
      event_types: tags,
    };

    return { ...post_obj };
  };

  saveConf = (is_publish = 0) => {
    let self = this;
    const { classes, dispatch } = this.props;
    const { tickets_tmp, start_date, start_time } = this.state;

    let post_obj = this.setSiteData();

    if (!post_obj) {
      return;
    }

    if (is_publish >= 0) post_obj.is_published = is_publish;
    //else post_obj.is_published = 0;

    userService.patchWrapperBody(
      `api/building/${this.state.id}`,
      post_obj,
      (data) => {
      const tmp_str = "Изменения сохранены";
        dispatch(userActions.set_error(tmp_str, "success"));
        
        if (is_publish >= 0) self.setState({ is_published: is_publish });

        self.getSiteInfo(self.state.id, true);
        //self.setUrlT(self.state.id);
      },
      (error) => {
        dispatch(userActions.set_error(error, "error"));
      }
    );

    this.saveBrief();
  };

  setUrlT = (id) => {
    let { urlt, url_id, url_old, format } = this.state;
    const { classes, dispatch } = this.props;

    let self = this;

    if (format == "offline") return;

    let postObj = {
      redirect: urlt,
      entity_type: "conference",
      entity_id: id,
    };

    if (urlt)
      if (
        (urlt.indexOf("http://") < 0 && urlt.indexOf("https://") < 0) ||
        confService.validateUrl(urlt) == false
      ) {
        dispatch(
          userActions.set_error("Неправильный формат ссылки", "warning")
        );
        return;
      }

    if (urlt != url_old) {
      if (!url_id) {
        if (urlt)
          userService.postWrapper(
            "api/link",
            postObj,
            (data) => {
              self.setState({
                url_old: data.data.redirect,
                url_id: data.data.id,
              });
            },
            (error) => {
              dispatch(userActions.set_error(error));
            }
          );
      } else if (urlt) {
        userService.patchWrapper(
          "api/link/" + url_id + "?redirect=" + urlt,
          (data) => {
            self.setState({
              url_old: data.data.redirect,
              url_id: data.data.id,
            });
          },
          (error) => {
            dispatch(userActions.set_error(error));
          }
        );
      } else {
        userService.delWrapper(
          "api/link/" + url_id,
          (data) => {
            self.setState({ url_id: "", url_old: "" });
          },
          (error) => {
            dispatch(userActions.set_error(error));
          }
        );
      }
    }
  };

  getUrlT = (id) => {
    let { urlt } = this.state;
    const { classes, dispatch } = this.props;

    let self = this;

    userService.getWrapper(
      "api/link?conference_id=" + id,
      (data) => {
        if (data.data.length > 0) {
          let { id, redirect } = data.data[0];
          self.setState({ url_id: id, urlt: redirect, url_old: redirect });
        }
      },
      (error) => {
        dispatch(userActions.set_error(error));
      }
    );
  };

  addConf = (is_publish = 0) => {
    const self = this;
    const { classes, dispatch } = this.props;
    const { selectedBldId } = this.state;

    let post_obj = this.setSiteData();

    if (!post_obj) return;

    if (selectedBldId) post_obj.building_id = selectedBldId;

    userService.postWrapper(
      "api/building",
      post_obj,
      (data) => {
        if (data.data && data.data > 0) {
          self.setState({ id: data.data, is_published: is_publish });
          self.getConfInfo(self.state.id, true);
        } else {
          const tmp_str = "Изменения сохранены";
          dispatch(userActions.set_error(tmp_str, "success"));
          self.getConfInfo(self.state.id, true);
        }
      },
      (error) => {
        dispatch(userActions.set_error(error));
      }
    );
  };

  onPublic = (n) => {
    const { id } = this.state;
    const { classes, dispatch } = this.props;

    if (id > 0) this.saveConf(n);
    else
      dispatch(
        userActions.set_error(
          "Необходимо сохранить площадку перед публикацией",
          "warning"
        )
      );
    //this.addConf(n);
  };

  onAdd = () => {
    this.addConf();
  };

  onSave = () => {
    this.saveConf(-1);
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  changeVal = (name, event) => {
    this.setState({ [name]: event.target.value });
  };

  changeDate = (index, event) => {
    if (index == 0) this.setState({ start_date: event.target.value });
    else this.setState({ end_date: event.target.value });
  };

  changeTime = (index, event) => {
    if (index == 0) this.setState({ start_time: event.target.value });
    else this.setState({ end_time: event.target.value });
  };

  handleChangeAdress = (index, col, event) => {
    /*
        let { blds } = this.state;
        let tmp_item = adresses[index];
        tmp_item[col] = event.target.value;
        adresses[index] = {...tmp_item}
        this.setState({adresses:adresses});
        */
  };

  deleteAddr = (id) => {
    const self = this;
    const { selectedBldId } = this.state;
    const { classes, dispatch } = this.props;

    if (selectedBldId > 0) {
      userService.delWrapper(
        `api/building/${selectedBldId}`,
        function (data) {
          if (data.status == 200) self.getBuildings();
        },
        function (error) {
          if (error != "") {
            self.setState({ error: error, isLoading: false });
            dispatch(userActions.set_error(error, "error"));
          }
        }
      );
    }
  };

  editAddr = (id) => {
    const { blds } = this.state;
    let bldAddr = "";
    let bldName = "";
    let bldEmail = "";
    for (let i = 0; i < blds.length; i++) {
      if (blds[i].id == id) {
        bldAddr = blds[i].address;
        bldName = blds[i].name;
        bldEmail = blds[i].email;
      }
    }

    this.setState({
      bldId: id,
      dAddress: bldAddr,
      dName: bldName,
      dEmail: bldEmail,
      bldDialog: true,
    });
  };

  addSiteRoom = () => {
    this.setState({
      bldId: 0,
      dAddress: "",
      dName: "",
      dEmail: "",
      bldDialog: true,
    });
  };

  closeSpDialog = () => {
    this.setState({ spId: 0, spDialog: false });
  };

  closeBldDialog = (val) => {
    const self = this;
    const { bldId } = this.state;
    const { classes, dispatch } = this.props;

    const post_data = {};

    if (val.address) post_data.address = val.address;
    if (val.name) post_data.name = val.name;
    if (val.email) post_data.email = val.email;

    if (post_data && post_data.name) {
      if (bldId > 0) {
        userService.patchWrapperBody(
          `api/building/${bldId}`,
          post_data,
          (data) => {
            if (data.status == 200) self.getBuildings();
          },
          (error) => {
            if (error != "") {
              self.setState({ error, isLoading: false });
              dispatch(userActions.set_error(error, "error"));
            }
          }
        );
      } else {
        userService.postWrapper(
          "api/building",
          post_data,
          (data) => {
            if (data.status == 200) self.getBuildings();
          },
          (error) => {
            if (error != "") {
              self.setState({ error, isLoading: false });
              dispatch(userActions.set_error(error, "error"));
            }
          }
        );
      }

      this.setState({ bldDialog: false });
    } else {
      this.setState({ bldDialog: false });
    }
  };

  render() {
    const self = this;
    const {
      id,
      name,
      comment,
      is_published,
      map_link,
      map_site,
      tags,
      address,
      rooms_data,
      tmpitem,
      img_url,
      feedback,
    } = this.state;
    const { classes } = this.props;
    const { selectedSite, isConfSelected } = this.props.user;

    let conf_url =
      window.location.protocol + "//" + window.location.host + "/event/" + id;

    return (
      <div className="layout__wrap">
        {(
          <div className="layout__cols">
            <div className="layout__block">
              <div className="form">
                <div className="form__col form__col-small">
                  <div className="form__group">
                    <span className="form__label">Изображение</span>

                    <div className="file">
                      {id > 0 && (
                        <Dropzone onDrop={this.onDrop} class="visually--hidden">
                          {({ getRootProps, getInputProps, isDragActive }) => {
                            return (
                              <div
                                style={{
                                  textAlign: "center",
                                  verticalAlign: "center",
                                  width: 250,
                                  height: 250,
                                  background: "#eee",
                                  borderRadius: 5,
                                }}
                                {...getRootProps()}
                                className={classNames("dropzone", {
                                  "dropzone--isActive": isDragActive,
                                })}
                              >
                                <input {...getInputProps()} />

                                {img_url && (
                                  <img
                                    src={img_url ? serverUrl + img_url : ""}
                                    alt=""
                                    class="object-fit is--cover"
                                  />
                                )}
                                {isDragActive ? (
                                  <p
                                    style={{
                                      fontSize: 12,
                                      position: "absolute",
                                      top: 110,
                                      left: 15,
                                    }}
                                  >
                                    Загрузить файл
                                  </p>
                                ) : (
                                  <p
                                    style={{
                                      fontSize: 12,
                                      position: "absolute",
                                      top: 110,
                                      left: 15,
                                      width: 220,
                                      background: "#eee",
                                      padding: 10,
                                      borderRadius: 5,
                                    }}
                                  >
                                    {TEXT_DROP_FOR_LOAD}
                                  </p>
                                )}
                              </div>
                            );
                          }}
                        </Dropzone>
                      )}
                      {!id > 0 && (
                        <div
                          style={{
                            textAlign: "center",
                            verticalAlign: "center",
                            width: 250,
                            height: 250,
                            backgroundColor: "#eee",
                            borderRadius: 5,
                          }}
                        >
                          <span>Сохраните площадку чтобы добавить лого</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form__col">
                  <div className="form__group">
                    <label htmlFor="text1" className="form__label">
                      Название площадки
                    </label>
                    <input
                      id="text1"
                      type="text"
                      className="form__input"
                      name="name"
                      value={name}
                      onChange={this.handleChange}
                      placeholder="Название площадки"
                    />
                  </div>

                  <div className="form__group">
                    <label htmlFor="text1" className="form__label">
                      Адрес площадки
                    </label>
                    <input
                      id="text1"
                      type="text"
                      className="form__input"
                      name="address"
                      value={address}
                      onChange={this.handleChange}
                      placeholder="Адрес площадки"
                      style={{ marginRight: 10, marginBottom: 10 }}
                    />

                    <input
                      id="start"
                      value={map_link}
                      name="trip-start"
                      onChange={(e) => this.changeVal("map_link", e)}
                      style={{ marginRight: 10, marginBottom: 10 }}
                      placeholder="Ссылка на карту"
                    />
                    <input
                      id="start"
                      value={map_site}
                      name="trip-start"
                      onChange={(e) => this.changeVal("map_site", e)}
                      style={{ marginRight: 10 }}
                      placeholder="Ссылка на маршрут до площадки"
                    />
                  </div>

                  <div className="form__group">
                    <label htmlFor="text1" className="form__label">
                      Возможные типы мероприятий (через запятую)
                    </label>
                    <input
                      id="text1"
                      type="text"
                      className="form__input"
                      name="tags"
                      value={tags}
                      onChange={this.handleChange}
                      placeholder="Возможные типы мероприятий"
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="form__group">
                  <label htmlFor="area1" className="form__label">
                    Описание площадки
                  </label>

                  <textarea
                    id="area1"
                    className="form__input"
                    placeholder="Текст"
                    name="comment"
                    onChange={this.handleChange}
                    value={comment}
                  ></textarea>
                </div>
              </div>

              <div class="form__group">
              
                  <h3>Отзывы</h3>
                  <div style={{marginTop:10}}>
                  {feedback.length > 0 &&
                    feedback.map((item) => (
                      <div style={{ marginTop: 5 }}>
                        Оценка: {item.score}, Отзыв:{" "}
                        {item.report ? item.report : "нет текста"}
                      </div>
                    ))}
                </div>
              </div>

              <div class="form__group">
                <div class="form__group-top">
                  <span class="form__label">Помещения</span>
                  <button
                    type="button"
                    class="form__btn"
                    onClick={this.addTmpRoom}
                  >
                    <svg width="29" height="29">
                      <use href="./img/sprite.svg#add-icon"></use>
                    </svg>
                    <span>Добавить</span>
                  </button>
                </div>

                {tmpitem && (
                  <RoomItem
                    number={tmpitem.number}
                    description={tmpitem.description}
                    equipment={tmpitem.equipment}
                    seats={tmpitem.seats}
                    index={-1}
                    time_from={
                      tmpitem.schedule &&
                      tmpitem.schedule.base &&
                      tmpitem.schedule.base.hours &&
                      tmpitem.schedule.base.hours.from
                        ? tmpitem.schedule.base.hours.from
                        : ""
                    }
                    time_to={
                      tmpitem.schedule &&
                      tmpitem.schedule.base &&
                      tmpitem.schedule.base.hours &&
                      tmpitem.schedule.base.hours.to
                        ? tmpitem.schedule.base.hours.to
                        : ""
                    }
                    price_hour={
                      tmpitem.schedule &&
                      tmpitem.schedule.base &&
                      tmpitem.schedule.base.cost
                        ? tmpitem.schedule.base.cost
                        : ""
                    }
                    price_day={
                      tmpitem.schedule &&
                      tmpitem.schedule.base &&
                      tmpitem.schedule.base["daycost-for-hourrooms"]
                        ? tmpitem.schedule.base["daycost-for-hourrooms"]
                        : ""
                    }
                    handleChange={self.roomInputChange}
                    saveRoom={self.saveRoom}
                    delRoom={self.delRoom}
                    clickExpand={self.clickExpand}
                    isClosed={self.state.roomOpenState["-1"]}
                  />
                )}
                <div>
                  {rooms_data.length > 0 &&
                    rooms_data.map(function (item, index) {
                      return (
                        <RoomItem
                          number={item.number}
                          description={item.description}
                          equipment={item.equipment}
                          seats={item.seats}
                          index={index}
                          time_from={
                            item.schedule &&
                            item.schedule.base &&
                            item.schedule.base.hours &&
                            item.schedule.base.hours.from
                              ? item.schedule.base.hours.from
                              : ""
                          }
                          time_to={
                            item.schedule &&
                            item.schedule.base &&
                            item.schedule.base.hours &&
                            item.schedule.base.hours.to
                              ? item.schedule.base.hours.to
                              : ""
                          }
                          price_hour={
                            item.schedule &&
                            item.schedule.base &&
                            item.schedule.base.cost
                              ? item.schedule.base.cost
                              : ""
                          }
                          price_day={
                            item.schedule &&
                            item.schedule.base &&
                            item.schedule.base["daycost-for-hourrooms"]
                              ? item.schedule.base["daycost-for-hourrooms"]
                              : ""
                          }
                          handleChange={self.roomInputChange}
                          saveRoom={self.saveRoom}
                          delRoom={self.delRoom}
                          clickExpand={self.clickExpand}
                          isClosed={self.state.roomOpenState[index + ""]}
                        />
                      );
                    })}
                </div>
              </div>
            </div>

            <div className="layout__col layout__col-aside">
              <div className="layout__block">
                <h2 className="layout__block-title">Карточка площадки</h2>

                <div className="layout__block-monitor">
                  <img
                    src={img_url ? serverUrl + img_url : confLogo}
                    alt=""
                    className="layout__block-img"
                  />
                </div>

                <div className="layout__inf">
                  <h3 className="layout__inf-title">{name}</h3>
                  <h3>
                    {id > 0 && is_published > 0 && (
                      <div className="form__group">
                        <a href={conf_url} style={{ fontSize: 18 }}>
                          <span
                            style={{
                              color: "blue",
                              textDecoration: "underline",
                            }}
                          >
                            {conf_url}
                          </span>
                        </a>
                        <CopyContentIcon
                          onClick={() =>
                            navigator.clipboard.writeText(conf_url)
                          }
                          width="20"
                          height="20"
                          color="primary"
                          style={{
                            marginLeft: 5,
                            paddingTop: 5,
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    )}
                  </h3>

                  <p className="layout__inf-text">{address}</p>

                  <ul className="layout__inf-blocks">
                    <li className="layout__inf-block"></li>
                    <li className="layout__inf-block">
                      <div className="layout__inf-media">
                        <p className="layout__inf-contains">
                          <strong>{rooms_data.length}</strong> <br />
                          залов
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="layout__block-btns">
                  <div className="layout__block-btn">
                    <button
                      className="btn btn--border"
                      type="button"
                      onClick={this.resetConf}
                    >
                      Сбросить
                    </button>
                  </div>

                  <div className="layout__block-btn">
                    {id == 0 && (
                      <button
                        onClick={this.onAdd}
                        className="btn"
                        type="submit"
                      >
                        {SAVE_CONF_TEXT}
                      </button>
                    )}
                    {id > 0 && (
                      <button
                        onClick={this.onSave}
                        className="btn"
                        type="submit"
                      >
                        {SAVE_CONF_TEXT}
                      </button>
                    )}
                  </div>
                  <div className="layout__block-btn">
                    <button
                      onClick={() => this.onPublic(1 - is_published)}
                      className={id > 0 ? "btn" : "btn_disabled"}
                      type="submit"
                      title={id > 0 ? "" : "Сперва сохраните площадку"}
                    >
                      {is_published == 0
                        ? "Опубликовать площадку"
                        : "Снять с публикации"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { app, user, authentication } = state;

  return {
    user,
    app,
    authentication,
  };
}

const connectedCreateSitePage = connect(mapStateToProps)(CreateSitePage);
export { connectedCreateSitePage as CreateSitePage };
