import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import * as React from "react";

import "../css/style.css";
import i18next from "i18next";
import { Checkbox, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup } from "@mui/material";

export default function UserInfoDialog(props) {
  const [data, setData] = React.useState({});
  
  const { handleClose, flds, optionalFields, showContactsSetting, dialogOpen } = props;
  if (showContactsSetting === "show" && data && !Object.hasOwn(data, "show_contacts")) {
    setInfo("show_contacts", []);
  }

  function openPdf() {
    window.open(process.env.REACT_APP_USER_AGR, "_blank");
  }

  function cancelData() {
    handleClose(null);
    setData(null);
  }

  function saveData() {
    if (data && flds) {
      let isOk = true;

      for (let i = 0; i < flds.length; i++) {
        let itemId = flds[i];
        if (typeof flds[i] === 'object') { //custom field
          itemId = Object.keys(flds[i])[0];
        }
        if (!data[itemId]) {
          isOk = false;
          break;
        }
      }

      if (isOk) {
        handleClose({ ...data });
        setData(null);
      } else {
         alert(i18next.t("too_short_fields"));
      }
    }
  }

  function setInfo(name, val) {
    let tmpData = { ...data };
    tmpData[name] = val;
    setData(tmpData);
  }

  function showFields(flds, required) {
    return flds && flds.map(function (item, index) {
      let itemId = item;
      let itemLabel = i18next.t("ank_" + item);
      if (typeof item === 'object') { //custom field
        itemId = Object.keys(item)[0];
        const itemInfo = item[itemId];
        itemLabel = itemInfo.title;
        if (itemInfo.type !== 'text') {
          const itemOptions = itemInfo.items;
          if (itemInfo.type === 'radio') {
            return (itemOptions &&
              <div key={itemId}>
                <FormLabel>{itemLabel}</FormLabel>
                <RadioGroup required={required} key={'anketa-radio-' + itemId}>
                  {itemOptions.map(item => <FormControlLabel 
                    control={<Radio />} 
                    label={item.label} 
                    key={'anketa-option-' + item.id} 
                    value={item.id}
                    onChange={e => setInfo(itemId, e.target.checked ? [item.id] : [])}
                  />)}
                </RadioGroup>
              </div>
            );
          }
          if (itemInfo.type === 'checkbox') {
            return (itemOptions &&
              <div key={itemId}>
                <FormLabel>{itemLabel}</FormLabel>
                <FormGroup required={required} key={'anketa-checkbox-' + itemId}>
                  {itemOptions.map(item => <FormControlLabel 
                    control={<Checkbox 
                      checked={data && data[itemId] && data[itemId].indexOf(item.id) !== -1} 
                      onChange={function (e) {
                        let value = data ? (data[itemId] || []) : [];
                        if (e.target.checked) {
                          value.push(item.id);
                          value = [... new Set(value)];
                        } else {
                          value = value.filter(valueItem => valueItem != item.id);
                        }
                        setInfo(itemId, value)
                      }}
                    />} 
                    label={item.label} 
                    key={'anketa-option-' + item.id} 
                    value={item.id}
                  />)}
                </FormGroup>
              </div>
            );
          }
        }
      }
      return (
        <TextField
          autoFocus={index === 0}
          margin="dense"
          id={itemId}
          key={itemId}
          label={itemLabel}
          fullWidth
          variant="standard"
          value={data && data[itemId] ? data[itemId] : ""}
          required={required}
          onChange={(e) => setInfo(itemId, e.target.value)}
        />
      );
    })
  }

  return (
    <Dialog open={dialogOpen}>
      <DialogTitle>{i18next.t("fill_anket")}</DialogTitle>
      <DialogContent style={{ minWidth: 280, maxWidth: 580 }}>
        {showFields(flds, true)}
        {showFields(optionalFields, false)}
        {showContactsSetting === "show" ? showFields([{
          "show_contacts": {
            title: i18next.t("participation_settings"),
            type: "checkbox",
            items: [{id: -1, label: i18next.t("show_contacts_setting")}],
          }
        }], false) : ""}
        <div style={{ marginTop: 20, fontSize: 16 }}>
          {i18next.t("agr_pd_1")}
          <span
            style={{
              color: "#0f2d51",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={openPdf}
          >
            {i18next.t("agr_pd_2")}
          </span>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={saveData}>{i18next.t("registry")}</Button>
        <Button onClick={cancelData}>{i18next.t("btn_cancel")}</Button>
      </DialogActions>
    </Dialog>
  );
}
