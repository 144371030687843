import React from "react";
import { connect } from "react-redux";
import { userService } from "../_services";
import { serverUrl } from "../_constants";
import { userActions } from "../_actions";
import DropFile from "../_components/DropFile";

import "../css/style.css";

import i18next from "i18next";
import RefillBalance from "./Settings/RefillBalance";
import Requisites from "./Settings/Requisites";

class SettingsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      req_list: [],
      req_data: [],
      tariff_id: 0,
      tariff_name: "",
      tariff_actions: null,
      tariff_expire: null,
      balance: "0",
      add_money: "0",
    };
  }

  componentDidMount() {
    this.getTarifInfo();
	  this.getUserInfo();
  }

  getUserInfo = () => {
    let self = this;

    userService.getWrapper(
      "api/user",
      data => {
          let req_list = Object.values(data.data.all_requisites);
          let req_keys = Object.keys(data.data.all_requisites);
      
          let req_data = [];
          for (let i = 0; i < req_list.length; i++) {
            req_data[i] = { id: "", val: "" };
          }
      
          for (let j = 0; j < data.data.requisites.length; j++) {
            let req_id = data.data.requisites[j].requisite_key_id + "";
            let id = data.data.requisites[j].id + "";
            let val = data.data.requisites[j].value;
        
            let new_id = req_keys.indexOf(req_id);
            if (new_id >= 0) req_data[new_id] = { id: id, val: val };
          }
      
          self.getTarifsList(data.data.tariff_id);
          this.setState({
            req_list,
            req_data,
            balance: data.data.balance,
            tariff_id: data.data.tariff_id,
            tariff_expire: data.data.tariff_expire,
            id: data.data.id,
          });

        },
        (error) => {
          console.log(error);
        }
	  );
  }

  getTarifsList = (tariff_id) => {
    userService.getWrapper(
      "api/tariff",
      (data) => {
        let tariff_info = data.data.filter((e) => e.id == tariff_id);

        if (tariff_info.length > 0)
          this.setState({
            tariff_name: tariff_info[0].name,
            tariff_actions: tariff_info[0].tariff_actions,
          });
      },
      (error) => {
        console.log(error);
      }
    );
  };

  addMoney = () => {
    const { add_money, id } = this.state;
    let self = this;

    if (add_money<=0)
      return;

    let url = `${serverUrl}replenish-balance/${id}?sum=${add_money}`
    window.location.href = url;
  }

  getTarifInfo = () => {
    userService.getWrapper(
      "api/tariff/restriction/user",
      (data) => {
        const keys = Object.keys(data.data);
        const result = keys.length > 0 ? keys.map(function(key) {
          const max = data.data[key]['max'] ?? i18next.t("unlimited");
          return <div>{key}: {data.data[key]['current'] ?? 0} / {max}</div>;
        }) : i18next.t("unlimited");
        this.setState({orgResriction: <div>{result}</div>});
      },
      (error) => {
        console.log(error);
      }
    );
  };

  setReq = (index, val) => {
    let { req_data } = this.state;

    req_data[index].val = val;
    this.setState({ req_data });
  };

  onSave = () => {
    let upd_data = { requisites: [] };

    const { req_list, req_data } = this.state;

    for (let i = 0; i < req_list.length; i++) {
      if (req_data[i].id || req_data[i].val) {
        let tmp_obj = {
          requisite_key_id: "" + (i + 1),
          value: req_data[i].val,
          action: req_data[i].id ? "update" : "insert",
          is_default: 0,
        };
        if (req_data[i].id) tmp_obj.id = req_data[i].id;

        upd_data.requisites.push(tmp_obj);
      }
    }

    userService.patchWrapperBody(
      "api/user",
      upd_data,
      (data) => {
        console.log(data);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  stateSetter = (key, value) => {
    this.setState({ [key]: value });
  };

  onDrop = (acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length > 0) {
      const self = this;
      const { dispatch, user } = this.props;
      const data_upload = new FormData();
      data_upload.append("avatar", acceptedFiles[0]);
      userService.uploadFile(
        `api/user/upload`,
        data_upload,
        data => {
          if (data && data.data && data.data.avatar) {
            user.info.avatar = data.data.avatar;
            localStorage.setItem("user", JSON.stringify(user.info));
            dispatch(userActions.setuser(user.info));
          } else {
            dispatch(userActions.set_error(i18next.t("cant_execute_request")));
          }
        },
        error => {
          dispatch(userActions.set_error(i18next.t("cant_execute_request")));
        }
      );
    }
  };

  render() {
    const self = this;
    const {
      req_list,
      req_data,
    } = this.state;

    return (
      <div className="layout">
        <h1 className="layout__title" style={{marginBottom: 10}}>{i18next.t("settings")}</h1>
        {this.props.user.info.type === "org" && <div>
          <div>Используется корпоративная лицензия</div>
          <RefillBalance 
            balance={this.state.balance} 
            moneyToAdd={this.state.add_money}
            moneyAdder={this.addMoney}
            stateSetter={this.stateSetter}
          />
          <h3 className="layout__title">{i18next.t("tarif")}</h3>
          <div>
            {i18next.t("org_restriction")}: {this.state.orgResriction ?? ''}
          </div>
        </div>}
        <div style={{ marginLeft: 10, marginTop: 10 }}>
          <span className="form__label">{i18next.t("image")}</span>
          <DropFile onDrop={this.onDrop} image={this.props.user.info.avatar}/>
        </div>
        {this.props.user.info.type === "org" && 
          <Requisites req_list={req_list} req_data={req_data} setReq={this.setReq} onSave={this.onSave} />
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;

  return {
    user,
  };
}

const connectedSettingsPage = connect(mapStateToProps)(SettingsPage);
export { connectedSettingsPage as SettingsPage };